.ag-body-viewport-wrapper.ag-layout-normal {
  overflow-x: scroll;
  overflow-y: scroll;
}

.ag-theme-balham{
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
}

// define colours
$colour-empty: #fafafa;
$colour-lightYellow: #ffff8d;
$colour-blue: #badeff;
$colour-darkPurple: #8747ffbe;
$colour-green: #e9ffd4;
$colour-darkGreen: #009620;
$colour-amber: #ffe8d9;
$colour-red: #f81b1b;
$colour-pink: #ffc5c5;

.ag-theme-balham {
  --ag-cell-horizontal-border: #bdc3c7 solid 1px;
}

.cell-unassigned{
  background-color: $colour-lightYellow;
}

.shift-cell{
  text-align: center;
}

:not(.ag-row-hover) {
  .cell-empty{
    background-color: $colour-empty;
  }
  .cell-planned {
    background-color: $colour-blue;
  }
  .cell-accepted {
    background-color: $colour-green;
  }
  .cell-inProgress {
    background-color: $colour-darkGreen;
    color: white;
  }
  .cell-pre-allocated {
    background-color: $colour-amber;
  }
  .cell-rejected{
    background-color: $colour-red;
    color: white;
  }
  .cell-not-started{
    background-color: $colour-red;
  }
  .cell-cancelled{
    background-color: $colour-pink;
  }
  .cell-completed {
    background-color: $colour-darkPurple;
    color: white;
  }
}

.ag-row-hover {
  .cell-empty {
    background-color: darken($color: $colour-empty, $amount: 10);
  }
  .cell-planned {
    background-color: darken($color: $colour-blue, $amount: 10);
  }
  .cell-pre-allocated {
    background-color: darken($color: $colour-amber, $amount: 10);
  }
  .cell-accepted {
    background-color: darken($color: $colour-green, $amount: 10);
  }
  .cell-inProgress {
    background-color: lighten($color: $colour-darkGreen, $amount: 10);
  }
  .cell-completed {
    background-color: darken($color: $colour-darkPurple, $amount: 10);
  }
  .cell-not-started {
    background-color: darken($color: $colour-red, $amount: 10);
  }
}