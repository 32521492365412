table.general-haulage-daily-table{
  tr{
    &:hover{
      background-color: #fffcef;
    }
  }
  td{
    vertical-align: top !important;
  }
}

.linked-shift-container{
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover{
    background-color: rgb(211, 218, 255);

    .text-info{
      color: #676a6c;
    }
  }
}