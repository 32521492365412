// Create colour variables
$color-green: #ccebc5;
$color-yellow: #ffffcc;
$color-red: #fbb4ae;

#utilisation-table{
  .utilisation-high{
    background-color: $color-green;
    color: darken($color: $color-green, $amount: 50);
  }
  .utilisation-medium{
    background-color: $color-yellow;
    color: darken($color: $color-yellow, $amount: 70);
  }
  .utilisation-low{
    background-color: $color-red;
    color: darken($color: $color-red, $amount: 50);
  }

tr{
  td{
    font-weight: 600;
    border: 1px solid #fafafa;
  }
}

  tr:hover{
    .utilisation-high{
      background-color: darken($color: $color-green, $amount: 10);
    }

    .utilisation-medium{
      background-color: darken($color: $color-yellow, $amount: 10);
    }

    .utilisation-low{
      background-color: darken($color: $color-red, $amount: 10);
    }
  }
}

#customer-weekly-summary-table{
  .increase{
    background-color: $color-green;
    color: darken($color: $color-green, $amount: 50);
  }
  .decrease{
    background-color: $color-red;
    color: darken($color: $color-red, $amount: 50);
  }

  tr{
    td{
      font-weight: 600;
      border: 1px solid #ccc;
    }
  }

  tr:hover{
    .increase{
      background-color: darken($color: $color-green, $amount: 10);
    }
    .decrease{
      background-color: darken($color: $color-red, $amount: 10);
    }   
  }
}