/* DataTables */
div.dataTables_wrapper div.dataTables_filter {
  text-align: left;
}

.table.dataTable thead {
  & > tr.list-view-col-filters {
    & > th {
      padding: 0;
      font-weight: 400;
      border-top: 1px solid #ccc;

      input {
        max-width: none;
        width: 100%;
        border: 0;
        background: transparent;
        padding-left: 6px;
        font-family: 'FontAwesome', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif; // For placeholder icon
        -moz-transition: background linear 0.2s;
        -o-transition: background linear 0.2s;
        -webkit-transition: background linear 0.2s;
        transition: background linear 0.2s;
        border-radius: 0;
        font-size: 10px;

        &:focus {
          background: #FFFEEC;
          border-bottom: 1px solid #ccc;
        }
      }
    }
  }
}

.is-sortable {
  &:hover {
    cursor: pointer;
  }

  &.sorted {
    color: #54abd9;
  }
}

.table{
  margin-bottom: 0;
}