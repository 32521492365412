.file-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px; 
}

.file-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px; 
  height: 200px; 
  border: 1px solid #ccc; 
  border-radius: 4px;
  box-sizing: border-box;
  padding: 8px;

  &:hover{
    background-color: #efefef;
    cursor: pointer;
  }
}

.file-image {
  width: 100%;
  height: 100px;
  object-fit: cover; 
  margin-bottom: 8px; 
}

.file-in-modal-container{
  img{
    width: 100%;
  }
}